<template>
  <div class="wrap" v-loading="loading">
    <topOperatingButton
      :disabled="disabled"
      :showAudit="false"
      :isAuditBillBtn="false"
      :isExamineBtn="true"
      @submitForm="submitForm(false)"
      @addBill="addBill(true)"
      @auditBill="auditBill"
      @handleAdd="handleAdd"
      @getQuit="getQuit"
      id="topOperatingButton">
    </topOperatingButton>
    <el-form
      ref="form"
      :model="birthdayGiftForm"
      :rules="rules"
      label-width="96px"
    >
      <!-- 顶部按钮 -->
      <!-- <div class="btnTOP">
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="submitForm(true)"
          >保存
        </el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="addBill"
          >保存并新增
        </el-button>
        <el-button
          @click="auditBill('审核')"
          icon="iconfont icon-a-weibiaoti-1_huaban1fuben28"
          size="mini"
          :disabled="showAudit"
          type="success"
          >审核</el-button
        >
        <el-button
          class="marL10"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          v-show="showAdd"
          @click="handleAdd"
          >新增</el-button
        >
        <el-button size="small" @click="getQuit">退出</el-button>
      </div> -->
      <!-- 基本信息 -->
      <cardTitleCom
        cardTitle="基本信息"
        :auditOrder="auditOrder"
        :noAuditOrder="noAuditOrder"
        :stopOrder="stopOrder"
        :deleteOrder="deleteOrder"
        :disableOrder="disableOrder"
      >
        <template slot="cardContent">
          <div class="tableContent x-x">
            <el-form-item label="方案编号" prop="billNo">
              <el-input
                class="inputWidth marR15"
                :disabled="true"
                v-model="birthdayGiftForm.billNo"
                placeholder="方案编号"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案名称" prop="billName">
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="birthdayGiftForm.billName"
                placeholder="方案名称"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效时间" prop="begTime">
              <el-date-picker
                :disabled="disabled"
                v-model="datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                size="mini"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input
                class="inputRemark"
                :disabled="disabled"
                v-model="birthdayGiftForm.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 5 }"
                size="mini"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>

      <!-- 生日有礼规则设置 -->
      <cardTitleCom cardTitle="生日有礼规则设置">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 生日日期 -->
            <div class="marT20 marB10">
              <el-form-item
                label="推送时间生日前"
                prop="birthdayBeforeDay"
                label-width="140px"
              >
                <el-input
                  :disabled="disabled"
                  v-model="birthdayGiftForm.birthdayBeforeDay"
                  class="inputWidth marR10"
                  size="mini"
                  placeholder="请输入推送日期"
                ></el-input>
                <span class="fontS14">天</span>
              </el-form-item>
            </div>
            <!-- 适用会员 -->
            <div>
              <el-form-item
                label="适用会员"
                prop="vipLevelRangeType"
                label-width="100px"
              >
                <el-radio-group
                  :disabled="disabled"
                  v-model="birthdayGiftForm.vipLevelRangeType"
                >
                  <el-radio
                    v-for="dict in dict.type.fill_vip_level_range_type"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >>
                </el-radio-group>
              </el-form-item>
              <!-- 非全部会员 -->
              <div
                class="marL20"
                v-show="
                  birthdayGiftForm.vipLevelRangeType == 1 ||
                  birthdayGiftForm.vipLevelRangeType == 2
                "
              >
                <el-button
                  :disabled="disabled"
                  size="mini"
                  class="marB10"
                  @click="getOpenDialog('vip')"
                  >选择会员</el-button
                >
                <el-button
                  :disabled="disabled"
                  size="mini"
                  class="marB10"
                  @click="getOpenDialog('vipLevel')"
                  >选择会员级别</el-button
                >
                <el-table
                  style="width: 100%"
                  tooltip-effect="dark"
                  :data="birthdayGiftForm.vipLevelItem"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="!disabled"
                  >
                    <template v-slot="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(scope.$index, birthdayGiftForm.vipLevelItem)
                        "
                        type="text"
                        size="mini"
                      >
                        <i class="el-icon-remove operateDel" />
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="类型"
                    align="center"
                    prop="lineType"
                    width="123"
                  >
                    <template slot-scope="scope">
                      <dict-tag
                        :options="dict.type.member_type"
                        :value="parseInt(scope.row.lineType)"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="编号"
                    align="center"
                    prop="lineNo"
                    width="300"
                  />
                  <el-table-column
                    label="名称"
                    align="center"
                    prop="lineName"
                    width="300"
                  />
                  <el-table-column />
                </el-table>
              </div>
            </div>
            <!-- 奖励设置 -->
            <div class="marT10 marL26">
              <span class="fontS14">* 奖励设置</span>
            </div>
            <!-- 赠送积分 -->
            <div class="marL26 rewardPoints">
              <el-checkbox
                :disabled="disabled"
                v-model="birthdayGiftForm.isBirthdayGiftScore"
                >赠送积分</el-checkbox
              >
              <el-input
                :disabled="disabled"
                v-show="birthdayGiftForm.isBirthdayGiftScore"
                v-model="birthdayGiftForm.birthdayGiftScore"
                size="mini"
                class="inputWidth marL15 marR15"
              ></el-input>
              <span v-show="birthdayGiftForm.isBirthdayGiftScore">分</span>
            </div>
            <!-- 赠送优惠券 -->
            <div class="marL26">
              <el-checkbox
                :disabled="disabled"
                v-model="birthdayGiftForm.isBirthdayGiftCoupon"
                >赠送优惠券</el-checkbox
              >
              <!-- 选择赠送优惠券 -->
              <div v-show="birthdayGiftForm.isBirthdayGiftCoupon">
                <!-- @click="getChooseCoupon" -->
                <el-button
                  :disabled="disabled"
                  @click="getChooseCoupon('coupon')"
                  class="marB10 marT10"
                  size="mini"
                  >选择优惠券</el-button
                >
                <el-table
                  style="width: 100%"
                  tooltip-effect="dark"
                  :data="birthdayGiftForm.couponItem"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="80"
                    v-if="!disabled"
                  >
                    <template v-slot="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(scope.$index, birthdayGiftForm.couponItem)
                        "
                        type="text"
                        size="mini"
                      >
                        <i class="el-icon-remove operateDel" />
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="优惠券名称"
                    width="160"
                    align="center"
                    prop="couponCaseName"
                  />
                  <el-table-column
                    label="赠送数量(单个会员)"
                    align="center"
                    width="196"
                    prop="couponEmitQty"
                  />
                  <el-table-column
                    label="赠送方式"
                    align="center"
                    prop="couponEmitModeName"
                    show-overflow-tooltip
                  />
                  <el-table-column
                    label="生效方式"
                    align="center"
                    prop="couponEffectModeName"
                    show-overflow-tooltip
                  />
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 选择优惠券对话框 -->
    <selectCoupon
      :OpenCoupon.sync="openCoupon"
      :isRadio="true"
      :isSuingEditorIsRadio="true"
      @getCoupons="getPitchData($event, 'coupon')"
    />
    <Dialog
      ref="tablePage"
      :options.sync="dialogOptions"
      @handleEvent="handleEvent"
    />
  </div>
</template>

<script>
import { uniqWith } from 'lodash' //去重
import Dialog from '@/components/Dialog'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectCoupon from '@/views/components/selectCoupon' //优惠券组件
import { getBillNo } from '@/api/codeRule' //方案号
import {
  birthdayGiftAddAPI,
  birthdayGiftDetailAPI,
  birthdayGiftUpdateAPI,
  birthdayGiftIsStopAPI,
  birthdayGiftUpdateStatusAPI
} from '@/api/marketing/vipMarketing/birthdayGift' //会员生日有礼
export default {
  name: 'joinGiftDetail',
  dicts: ['fill_vip_level_range_type', 'member_type'],
  components: { Dialog, cardTitleCom, topOperatingButton, selectCoupon },
  data () {
    return {
      dialogType: 'vip',
      dialogOptions: {
          curType: '', //当前类型(门店/会员/商品/类别/品牌)
          title: '选择商品',
          width: 1250,
          show: false,
          type: 'TreeAndTable'
      },
      //选择会员开关
      couponIndex: '',
      //选择优惠券开关
      openCoupon: false,
      //单据时间
      datetime: undefined,
      //标识已审核
      auditOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      showAudit: true, //审核按钮禁用状态
      showAdd: false, //新增按钮
      disabled: false, //保存禁用
      openGoods: false, //选择商品信息开关
      queryCode: undefined, //传送商品参数
      loadingTable: false, //表格遮罩
      loading: false, //大盒子遮罩层
      //旧的表单
      oldForm: {},
      // 表单参数
      birthdayGiftForm: {
        billStatus: '',
        vipLevelRangeType: 0,
        vipLevelItem: [],
        couponItem: []
      },
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: '请到编码规则配置单据类型',
            trigger: ['blur', 'change']
          }
        ],
        billName: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        begTime: [
          {
            required: true,
            message: '有效时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        birthdayBeforeDay: [
          {
            required: true,
            message: '推送时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        vipLevelRangeType: [
          {
            required: true,
            message: '适用会员不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billId) {
          //获取方案详情
          const { data } = await birthdayGiftDetailAPI(vm.$route.query.billId)
          //方案赋值
          vm.birthdayGiftForm = data
          vm.dateAssembly(
            vm.birthdayGiftForm.begTime,
            vm.birthdayGiftForm.endTime
          )
          if (vm.birthdayGiftForm.couponItem.length > 0) {
            vm.birthdayGiftForm.couponItem.forEach((item, index) => {
              //组装数据
              let assemblyCouponObj = vm.assemblyCoupon(item)
              vm.$set(vm.birthdayGiftForm.couponItem, index, assemblyCouponObj)
            })
          }
          //原始方案赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.birthdayGiftForm))
        }
      } else {
        await vm.getOrderBillNo()
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.birthdayGiftForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.birthdayGiftForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave (to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.birthdayGiftForm.billId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.birthdayGiftForm))
    }
    next()
  },
  watch: {
    //侦听单据状态
    'birthdayGiftForm.billStatus': {
      handler (newVal) {
        if (this.birthdayGiftForm.billStatus == '2') {
          //新增按钮
          this.showAdd = true
          //打开审核图标
          this.auditOrder = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
        } else if (this.birthdayGiftForm.billStatus == '3') {
          //新增按钮
          this.showAdd = false
          //打开已删除图标
          this.deleteOrder = true
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //禁止全部输入按钮
          this.disabled = true
        } else if (this.birthdayGiftForm.billStatus == '0') {
          //新增按钮
          this.showAdd = false
          //未审核图标
          this.noAuditOrder = true
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (this.birthdayGiftForm.billStatus == '4') {
          //新增按钮
          this.showAdd = false
          //打开关闭图标
          this.stopOrder = true
          //未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (this.birthdayGiftForm.billStatus == '') {
          //引入源单按钮
          this.showImportBill = true
          //新增按钮
          this.showAdd = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //恢复全部输入
          this.disabled = false
        }
      },
      immediate: true
    },
    //单据日期
    datetime (newVal) {
      if (newVal) {
        this.birthdayGiftForm.begTime = newVal[0]
        this.birthdayGiftForm.endTime = newVal[1]
      } else {
        this.birthdayGiftForm.begTime = undefined
        this.birthdayGiftForm.endTime = undefined
      }
    }
  },
  methods: {
    getOpenDialog(value) {
      this.dialogType = value;
      this.dialogOptions = {
        title: value = 'vip' ? '选择会员' : "选择会员级别",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: this.dialogType }),
      }
    },
    async handleEvent (type, row) {
            if (row?.curTable) this.curTable = row.curTable
                console.log(this.curTable, 'this.curTable')
            switch (type) {
                case 'openCouponDialog':
                  this.dialogType = 'coupon'
                  //弹窗配置
                  this.dialogOptions = {
                    curTable: this.couponIndex == 'coupon'? 'couponItem' : 'recommendCouponItem',
                    curType: 'couponCaseId', //当前类型(会员/门店/优惠券)
                    title: '选择优惠券',
                    width: 1250,
                    show: true,
                    type: 'TreeAndTable',
                    isStep: true, //是否允许下一步(主要用于优惠券)
                    // formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStops: 0, isInvalid: 1}}})
                    formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStops: 0, isInvalids: [0, 1]}}})
                    // formData: this.$dialog({ key: 'coupon', option:{ queryParams: { isStop: 0, isInvalid: 1  } }})
                  }
                  this.dialogOptions.formData.table.radioSelect = true
                  this.dialogOptions.formData.table.mutiSelect = false
                break
                //适用时间回显
                case 'dialogChange':
                  if (this.dialogType == 'vip') {
                    this.vipListCom(this.dialogOptions.formData.table.check)
                  }else if (this.dialogType == 'vipLevel') {
                    this.vipLeveListCom(this.dialogOptions.formData.table.check)
                  }else {
                   let list = []
                    let dialogType = this.dialogOptions.curType
                    if (dialogType != 'timeDate') {
                        if (this.dialogOptions.formData.table.check.length <= 0) return
                        list = this.dialogOptions.formData.table.check
                        console.log(dialogType, 'dialogType')
                        console.log(list, 'list')
                        console.log(this.curTable, 'this.curTable')
                        // if (['vipLevelId'].includes(dialogType)) {
                        //     list = [...list, ...this.joinGiftForm.vipLevelItem]
                        //     list = uniqWith(list, (x, y) => x.vipLevelId == y.vipLevelId)
                        //     this.joinGiftForm.vipLevelItem = list
                        // }
                        // if (['shopId'].includes(dialogType)) {
                        //     list = [...list, ...this.joinGiftForm.vipLevelItem]
                        //     list = uniqWith(list, (x, y) => x.shopId == y.shopId)
                        //     this.joinGiftForm.shopItem = list
                        // }
                        if (dialogType =='couponCaseId') {
                          let curType = this.dialogOptions.curType
                          let arr = []
                          arr = this.birthdayGiftForm.couponItem
                          // if (this.couponIndex === 'newVip') {
                          //   arr = this.joinGiftForm.newCouponItem
                          // } else {
                          //   arr = this.joinGiftForm.recommendCouponItem
                          // }
                          const couponDrawModes = {
                              0: '每天',
                              1: '每周',
                              2: '每月',
                              3: '总共'
                          }
                          list = list.map(item => ({
                              ...item,
                              // couponEmitMode: item.couponDrawMode,
                              // couponDrawModeName: `${couponDrawModes[item.couponEmitMode]}${item.couponEveryDrawQty}张`,
                              // couponEveryEmitQty: item.couponEveryDrawQty,
                              largessCouponCaseId: item.largessCouponCaseId? item.largessCouponCaseId : item.couponCaseId
                          }))
                          list = [...list, ...arr]
                          list = uniqWith(list, (x, y) => x.largessCouponCaseId == y.largessCouponCaseId)
                          
                          console.log(list, 'list')
                          this.birthdayGiftForm.couponItem = list
                          // if (this.couponIndex === 'newVip') {
                          //   this.joinGiftForm.newCouponItem = list
                          // } else {
                          //   this.joinGiftForm.recommendCouponItem = list
                          // }
                        }
                    }
                    // if (['vipId', 'vipLevelId'].includes(dialogType)) {

                    //   this.joinGiftForm[this.curTable] = list
                    // }
                  }
                    break
                default:
                break
            }
        },
    //组装日期
    dateAssembly (begTime, endTime) {
      this.datetime = [begTime, endTime]
    },
    //保存并新增方案
    async addBill () {
      this.$confirm('是否保存并新增方案?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
        .then(async () => {
          // this.loading = true
          this.submitForm(true)
          // this.reset()
          // await this.getOrderBillNo()
          // this.loading = false
        })
        .catch()
    },
    //审核/删除/终止事件
    async auditBill (name) {
      if (name === '审核') {
        if (this.birthdayGiftForm.billStatus != '0') {
          this.$message.error('此单据不是未审核的状态哦~')
          return
        }
        const obj = {
          billIds: [this.birthdayGiftForm.billId],
          billStatus: '2'
        }
        //发送审核api
        const res1 = await birthdayGiftUpdateStatusAPI(obj)
        this.birthdayGiftForm = res1.data
        this.birthdayGiftForm.couponItem.forEach((item, index) => {
          //组装数据
          let assemblyCouponObj = this.assemblyCoupon(item)
          this.$set(this.birthdayGiftForm.couponItem, index, assemblyCouponObj)
        })
      }
      // try {
        await this.$confirm(`此单据已审核，是否要启用?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          const obj = {
            billIds: [this.birthdayGiftForm.billId],
            isStop: false
          }
          const res2 =  birthdayGiftIsStopAPI(obj)
          // this.birthdayGiftForm = res2.data
          // this.birthdayGiftForm.couponItem.forEach((item, index) => {
          //   //组装数据
          //   let assemblyCouponObj = this.assemblyCoupon(item)
          //   this.$set(this.birthdayGiftForm.couponItem, index, assemblyCouponObj)
          // })
        // 弹出提示
          this.$message.success('审核并启用成功')
        }).catch(() => {
          console.log(this.joinGiftForm, '5222')
          // this.$message.success('审核成功')
            // this.$message.success('审核成功')
        })
      // } catch {
        // 弹出提示
      // }
      if (this.birthdayGiftForm.couponItem.length > 0) {
        this.birthdayGiftForm.couponItem.forEach((item, index) => {
          //组装数据
          let assemblyCouponObj = this.assemblyCoupon(item)
          this.$set(this.birthdayGiftForm.couponItem, index, assemblyCouponObj)
        })
      }
    },
    /** 提交按钮 */
    async submitForm (isBool) {
      console.log(isBool, 'isBool')
      
      let valid = await this.$refs['form'].validate()
      console.log(valid, 'valid//');
      if (valid) {
        //适用会员
        if (
          (this.birthdayGiftForm.vipLevelRangeType == 1 ||
            this.birthdayGiftForm.vipLevelRangeType == 2) &&
          this.birthdayGiftForm.vipLevelItem.length <= 0
        ) {
          this.$message.error('适用对象不能为空')
          return
        }
        if (
          !this.birthdayGiftForm.isBirthdayGiftScore &&
          !this.birthdayGiftForm.isBirthdayGiftCoupon
        ) {
          this.$message.error('奖励设置不能为空')
          return
        }
        //赠送积分
        if (
          this.birthdayGiftForm.isBirthdayGiftScore &&
          !this.birthdayGiftForm.birthdayGiftScore
        ) {
          this.$message.error('赠送积分不能为空')
          return
        }
        //赠送优惠券
        if (
          this.birthdayGiftForm.isBirthdayGiftCoupon &&
          this.birthdayGiftForm.couponItem.length <= 0
        ) {
          this.$message.error('赠送优惠券不能为空')
          return
        }
        if (this.birthdayGiftForm.billId) {
          const res = await birthdayGiftUpdateAPI(this.birthdayGiftForm)
          this.birthdayGiftForm = res.data
          //重新赋值给表单，可以把id赋值
          this.birthdayGiftForm = res.data
          if (this.birthdayGiftForm.couponItem.length > 0) {
            this.birthdayGiftForm.couponItem.forEach((item, index) => {
              //组装数据
              let assemblyCouponObj = this.assemblyCoupon(item)
              this.$set(
                this.birthdayGiftForm.couponItem,
                index,
                assemblyCouponObj
              )
            })
          }
          if (isBool) {
            this.reset()
            this.$modal.msgSuccess('保存并修改方案成功')
          } else {
            this.$modal.msgSuccess('修改方案成功')
          }
          return true
        } else {
          const { data } = await birthdayGiftAddAPI(this.birthdayGiftForm)
          this.birthdayGiftForm = data
          //重新赋值给表单，可以把id赋值
          this.birthdayGiftForm = data
          if (this.birthdayGiftForm.couponItem.length > 0) {
            this.birthdayGiftForm.couponItem.forEach((item, index) => {
              //组装数据
              let assemblyCouponObj = this.assemblyCoupon(item)
              this.$set(
                this.birthdayGiftForm.couponItem,
                index,
                assemblyCouponObj
              )
            })
          }
          if (isBool) {
            this.reset()
            this.$modal.msgSuccess('保存并新增方案成功')
          } else {
            this.$modal.msgSuccess('新增方案成功')
          }
          return true
        }
      }
    },
    //会员确定键
    vipListCom (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.birthdayGiftForm.vipLevelItem.every(
          v => v.lineId != item.vipId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        return {
          lineId: v.vipId,
          lineType: 1,
          lineName: v.vipName,
          lineNo: v.vipNo
        }
      })
      this.birthdayGiftForm.vipLevelItem.push(...value)
      this.birthdayGiftForm.vipLevelItem =
        this.birthdayGiftForm.vipLevelItem.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //会员级别确定键
    vipLeveListCom (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.birthdayGiftForm.vipLevelItem.every(
          v => v.lineId != item.vipLevelId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        return {
          lineId: v.vipLevelId,
          lineType: 2,
          lineName: v.vipLevelName,
          lineNo: v.vipLevelNo
        }
      })
      this.birthdayGiftForm.vipLevelItem.push(...value)
      this.birthdayGiftForm.vipLevelItem =
        this.birthdayGiftForm.vipLevelItem.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //打开优惠券
    // getChooseCoupon () {
      // this.openCoupon = true
    // },
    getChooseCoupon (value) {
      // this.openCoupon = true
      this.couponIndex = value
      //弹窗
      this.handleEvent('openCouponDialog')
    },
    //优惠券确定键
    getPitchData (value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.birthdayGiftForm.couponItem.every(
          v => v.largessCouponCaseId != item.couponCaseId
        )
      })
      if (value.length == 0) return
      //组装数据
      let assemblyCouponObj = this.assemblyCoupon(value[0])
      let obj = {}
      value.forEach(v => {
        obj = {
          largessCouponCaseId: v.couponCaseId,
          couponCaseName: v.couponCaseName,
          couponEmitQty: v.couponEmitQty,
          ...assemblyCouponObj
        }
      })
      this.birthdayGiftForm.couponItem.push(obj)
      this.birthdayGiftForm.couponItem =
        this.birthdayGiftForm.couponItem.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.largessCouponCaseId === undefined)) ||
            !(item.largessCouponCaseId === undefined)
        )
    },
    //组装优惠券数据
    assemblyCoupon (value) {
      //发放方式
      const couponEmitModes = {
        0: '即时发放',
        1: '按天分期发放',
        2: '按周分期发放',
        3: '按月分期发放'
      }
      const {
        couponEmitMode,
        couponEveryEmitQty,
        couponEmitTimes,
        couponEmitInterval
      } = value
      let couponEmitModeName = couponEmitModes[couponEmitMode]
      if ([1, 2, 3].includes(couponEmitMode)) {
        const timeUnit =
          couponEmitMode === 1 ? '天' : couponEmitMode === 2 ? '周' : '月'
        couponEmitModeName += `,每次发放数量：${couponEveryEmitQty},分期次数：${couponEmitTimes},每次间隔：${couponEmitInterval}${timeUnit}`
      }
      //生效方式
      const couponEffectModes = {
        0: '即时生效， ',
        1: '延迟生效， ',
        2: '固定日期生效， '
      }
      const {
        couponEffectMode,
        couponEffectDays,
        couponEffectDate,
        couponValidDays
      } = value
      let couponEffectModeName = couponEffectModes[couponEffectMode]
      if (couponEffectMode === 0) {
        couponEffectModeName += `生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 1) {
        couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 2) {
        couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`
      }
      value.couponEmitModeName = couponEmitModeName
      value.couponEffectModeName = couponEffectModeName
      return value
    },
    //方案编号
    async getOrderBillNo () {
      this.birthdayGiftForm.billNo = await getBillNo(160304)
    },
    //删除会员、优惠券内容
    deleteRow (index, rows) {
      rows.splice(index, 1)
    },
    // 表单重置
    async reset () {
      //新增按钮
      this.showAdd = false
      //关闭已删除图标
      this.deleteOrder = false
      //关闭审核图标
      this.auditOrder = false
      //关闭未审核图标
      this.noAuditOrder = false
      //关闭关闭图标
      this.stopOrder = false
      //关闭已禁用图标
      this.disableOrder = false
      //保存禁用
      this.disabled = false
      this.datetime = undefined
      this.birthdayGiftForm = {
        billNo: '',
        billStatus: '',
        vipLevelRangeType: 0,
        isBirthdayGiftScore: false,
        isBirthdayGiftCoupon: false,
        vipLevelItem: [],
        couponItem: []
      }
      await this.getOrderBillNo()
      // this.resetForm('form')
    },
    //新增按钮
    async handleAdd () {
      this.loading = true
      this.reset()
      await this.getOrderBillNo()
      this.loading = false
    },
    //退出
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$destroy()
      this.$router.push('/marketing/vipMarketing/birthdayGift')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio, .el-checkbox {
  color: #333;
}
.wrap {
  background-color: #eaeaea;
  padding: 40px 10px 6px 10px;
  min-height: 100vh;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  .tableContent {
    margin: 15px 10px 20px 10px;
    //左边边距25px
    .marL26 {
      margin-left: 26px;
    }
    //提示
    .prompt {
      font-size: 14px;
      color: #a2a2a2;
    }
  }
  //赠送积分
  .rewardPoints {
    height: 40px;
    line-height: 40px;
  }
  //内部表单
  ::v-deep .el-form-item {
    padding: 0;
    margin: 0;
  }
  //阿里图标
  ::v-deep .iconfont {
    font-size: 12px;
  }
  //单据日期
  ::v-deep .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    width: 355px !important;
  }
  // /* 单选框选中后的字体颜色 */
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #333333 !important;
  }
  /* 复选框选中后的字体颜色 */
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #333333 !important;
  }
  ::v-deep .el-input--medium .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
}
</style>
