<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  vipMarketingTimesCardSummary,
  vipMarketingTimesCardSummarySummary,
} from "@/api/marketing/timesCard/qtyTotalQuery";
export default {
  name: "qtyTotalQuery",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "次卡汇总查询",
        rowKey: "shopId",
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "次卡编码/次卡名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "timesCardNos", label: "次卡编码" },
              { filter: "timesCardNames", label: "次卡名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "single",
        tabsColumns: [
          {
            title: "次卡汇总查询（按次卡）",
            type: "single",
            getSummaryApi: vipMarketingTimesCardSummarySummary,
            getListApi: vipMarketingTimesCardSummary,
            uuid: "d57c1be9-6ce8-0aa3-ae7d-0d4e1a0ad59c",
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/marketing/times/card/reportForms/vipMarketingTimesCardSummaryExport",
              exportName: "次卡汇总查询（按次卡）",
            },
            columns: [
              {
                prop: "timesCardNo",
                label: "次卡编码",
                minWidth: 150,
              },
              {
                prop: "timesCardName",
                label: "次卡名称",
                minWidth: 180,
              },
              {
                prop: "cardSalePrice",
                label: "价格",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "cardTotalCount",
                label: "次数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "usableCardCount",
                label: "剩余张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "usableCount",
                label: "剩余次数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "usableMoney",
                label: "剩余金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "purchaseCardCount",
                label: "购买张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "purchaseTotalCount",
                label: "购买总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "purchaseMoney",
                label: "购买金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "consumeCardCount",
                label: "消费张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "consumeTotalCount",
                label: "消费总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "consumeMoney",
                label: "消费金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "refundCardCount",
                label: "退卡张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "refundTotalCount",
                label: "退卡总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "refundMoney",
                label: "退卡金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "expireCardCount",
                label: "过期张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "expireTotalCount",
                label: "过期总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "expireMoney",
                label: "过期金额",
                minWidth: 100,
                sortable: true,
              },
            ],
            summary: [
              "cardSalePrice",
              "cardTotalCount",
              "usableCardCount",
              "usableCount",
              "usableMoney",
              "purchaseCardCount",
              "purchaseTotalCount",
              "purchaseMoney",
              "refundCardCount",
              "refundTotalCount",
              "refundMoney",
              "expireCardCount",
              "expireTotalCount",
              "expireMoney",
              "consumeCardCount",
              "consumeTotalCount",
              "consumeMoney",
            ],
          },
          {
            title: "次卡汇总查询（按门店）",
            type: "single2",
            getSummaryApi: vipMarketingTimesCardSummarySummary,
            getListApi: vipMarketingTimesCardSummary,
            uuid: "dc34ef57-fb06-1022-9a2a-5a9ee841d7ab",
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/marketing/times/card/reportForms/vipMarketingTimesCardSummaryExport",
              exportName: "次卡汇总查询（按门店）",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "timesCardNo",
                label: "次卡编码",
                minWidth: 150,
              },
              {
                prop: "timesCardName",
                label: "次卡名称",
                minWidth: 180,
              },
              {
                prop: "cardSalePrice",
                label: "价格",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "cardTotalCount",
                label: "次数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "usableCardCount",
                label: "剩余张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "usableCount",
                label: "剩余次数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "usableMoney",
                label: "剩余金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "purchaseCardCount",
                label: "购买张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "purchaseTotalCount",
                label: "购买总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "purchaseMoney",
                label: "购买金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "consumeCardCount",
                label: "消费张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "consumeTotalCount",
                label: "消费总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "consumeMoney",
                label: "消费金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "refundCardCount",
                label: "退卡张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "refundTotalCount",
                label: "退卡总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "refundMoney",
                label: "退卡金额",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "expireCardCount",
                label: "过期张数",
                minWidth: 100,
                sortable: true,
              },
              {
                prop: "expireTotalCount",
                label: "过期总次数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "expireMoney",
                label: "过期金额",
                minWidth: 100,
                sortable: true,
              },
            ],
            summary: [
              "cardSalePrice",
              "cardTotalCount",
              "usableCardCount",
              "usableCount",
              "usableMoney",
              "purchaseCardCount",
              "purchaseTotalCount",
              "purchaseMoney",
              "refundCardCount",
              "refundTotalCount",
              "refundMoney",
              "expireCardCount",
              "expireTotalCount",
              "expireMoney",
              "consumeCardCount",
              "consumeTotalCount",
              "consumeMoney",
            ],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          if (this.options.tabColumnType === "note") {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billNo: row.billNo,
                type: "Update",
              },
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
