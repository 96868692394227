<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  vipMarketingTimesDetail,
  vipMarketingTimesDetailSummary,
} from "@/api/marketing/timesCard/vipQuery";
export default {
  name: "vipQuery",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid: "37f87b82-d195-eddd-4ae7-ee57d9f9971b",
        title: "会员次卡明细",
        getListApi: vipMarketingTimesDetail,
        getSummaryApi: vipMarketingTimesDetailSummary,
        listNo: true, // 序号
        exportOption: {
          show: true,
          fastExportUrl:
            "/api/system/marketing/times/card/reportForms/vipMarketingTimesDetailExport",
          exportName: "会员次卡明细",
        },
        //搜索
        search: [
          {
            label: "购买时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "会员电话" },
              { filter: "timesCardNos", label: "次卡编码" },
              { filter: "timesCardNames", label: "次卡名称" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //表格
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "timesCardNo",
            label: "次卡编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "timesCardName",
            label: "次卡名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "totalCount",
            label: "总次数",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "usableCount",
            label: "剩余次数",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "salePrice",
            label: "购买金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "购买时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "limitEndDate",
            label: "到期时间",
            minWidth: 155,
            align: "center",
          },
        ],
        summary: ["totalCount", "usableCount", "salePrice"],
      },
    };
  },
};
</script>
