<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";

import {
  CouponList,
  isStopCoupon,
  deleteCoupon,
  copyCoupon,
} from "@/api/marketing/coupon/case"; //接口api
import {
  birthdayGiftListAPI,
  birthdayGiftUpdateStatusAPI,
  birthdayGiftIsStopAPI,
} from "@/api/marketing/vipMarketing/birthdayGift"; //会员生日有礼
export default {
  name: "specialprice",
  components: { TablePage },
  dicts: ["fill_vip_level_range_type"],
  data() {
    return {
      options: {
        uuid: "8830da0e-24c6-c3bd-a2c0-c9ba3286bc61",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: birthdayGiftListAPI,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billNo",
        labelWidth: "80",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "方案编号、方案名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "方案编号" },
              { filter: "billNames", label: "方案名称" },
            ],
          },
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "bill_status",
            option: { label: "方案状态", seniorSearch: true, option: { multiple: true } },
          }),
          // , option: { multiple: true }
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            icon: "el-icon-document",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "方案编号",
            type: "link",
            click: "routerLink",
            minWidth: 200,
          },
          {
            prop: "billName",
            label: "方案名称",
            minWidth: 155,
          },
          {
            prop: "vipLevelRangeType",
            label: "适用会员",
            type: "dict",
            minWidth: 120,
            dict: "fill_vip_level_range_type",
          },
          {
            prop: "begTime",
            label: "有效时间",
            minWidth: 160,
          },
          {
            prop: "endTime",
            label: "失效时间",
            minWidth: 160,
          },
          {
            prop: "billStatusName",
            label: "方案状态",
            minWidth: 160,
          },
          {
            prop: "isStopName",
            label: "启用状态",
            minWidth: 160,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "birthdayGiftDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "birthdayGiftDetail",
            });
          }
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData);
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData);
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData) {
      let name = "";
      let isStop = "";
      let billStatus = "";
      if (command === "审核") {
        name = "审核";
        billStatus = "2";
      } else if (command === "反审核") {
        name = "反审核";
        billStatus = "0";
      } else if (command === "启用") {
        name = "启用";
        isStop = false;
      } else if (command === "禁用") {
        name = "禁用";
        isStop = true;
      } else if (command === "删除") {
        name = "删除";
        billStatus = "3";
      }
      const billIds = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      // return
      console.log(name, "name");
      const $this = this;
      this.$modal.confirm(`是否确认${name}方案编号为"` + billNo + '"?').then(function () {
        if (["启用", "禁用"].includes(name)) {
          birthdayGiftIsStopAPI({ billIds: [...billIds], isStop: isStop }).then((res) => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
            if (!isStop) {
              $this.$modal.msgSuccess("方案启用成功");
            } else {
              $this.$modal.msgSuccess("方案禁用成功");
            }
          });
        } else {
          birthdayGiftUpdateStatusAPI({
            billIds: [...billIds],
            billStatus: billStatus,
          }).then((res) => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
            $this.$modal.msgSuccess(`${name}成功`);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
