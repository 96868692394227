<template>
  <div class="wrap" v-loading="loading">
    <topOperatingButton
      :submitText="sendForm.sendType == 1 ? '立即发送' : '定时发送'"
      :isExamineBtn="false"
      :isAuditBillBtn="false"
      :isSubmitAddBtn="false"
      :isReset="true"
      @submitForm="submitForm"
      @reset="reset"
      @getQuit="shutDown"
      id="topOperatingButton"
    >
    </topOperatingButton>
    <el-form ref="form" :model="sendForm" :rules="rules">
      <!-- 顶部按钮 -->
      <!-- <div class="btnTOP">
        <el-button type="primary" size="mini" @click="submitForm"
          >保存</el-button
        >
        <el-button size="mini" @click="reset">重置</el-button>
        <el-button size="mini" @click="shutDown">关闭</el-button>
      </div> -->
      <!-- 基本信息盒子 -->
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="基本信息" class="cardMessage">
        <template slot="cardContent">
          <!-- 整体盒子 -->
          <div class="wholeMessage">
            <!-- 第一行 -->
            <div class="marB20">
              <span class="fS14MR10"
                >您剩余可用短信量
                <span class="fontS18 annotateBlue">{{ bookTotal }}</span>
                条</span
              >
              <span
                class="fontS14B cursorP marL20"
                @click="$router.push('/marketing/sms/buy')"
                >短信购买</span
              >
            </div>
            <!-- 第二行 -->
            <div class="marB10">
              <span class="asterisk fontS14">*</span>
              <span class="fontS14 marR20 ft3">请选择发送对象</span>
              <el-button size="mini" class="marR10" @click="getOpenDialog('vip')"
                >添加会员</el-button
              >
              <el-button size="mini" class="marR10" @click="getOpenDialog('customer')"
                >添加客户</el-button
              >
              <el-button size="mini" @click="getEmptyOut">清空全部</el-button>
            </div>
            <!-- 第三行 -->
            <div class="marB20">
              <el-form-item prop="detailItem">
                <el-table
                  v-loading="loadingTable"
                  tooltip-effect="dark"
                  :data="sendForm.detailItem"
                  border
                  max-height="500"
                  ref="multipleTable"
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    prop="userId"
                    width="80"
                    type="index"
                  />
                  <el-table-column align="center" label="操作" width="50">
                    <template v-slot="scope">
                      <i
                        @click="row('del', scope.$index)"
                        class="el-icon-remove operateDel"
                      ></i>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="名称"
                    align="center"
                    prop="clientName"
                    width="250"
                  />
                  <el-table-column
                    label="手机号"
                    align="center"
                    prop="clientTel"
                    width="250"
                  />
                  <el-table-column align="center" prop="goodsName" />
                </el-table>
              </el-form-item>
            </div>
            <!-- 第四行 -->
            <el-form-item prop="smsType" label-width="80px" label="短信类型">
              <el-select
                size="mini"
                class="inputRemark marB20"
                v-model="sendForm.smsType"
                placeholder="请选择短信类型"
                filterable
              >
                <el-option label="通知类" :value="1"></el-option>
                <el-option label="营销类" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <!-- 第四行 -->
            <el-form-item
              prop="sendSignature"
              label-width="80px"
              label="短信签名"
            >
              <el-select
                size="mini"
                class="inputRemark"
                v-model="sendForm.sendSignature"
                placeholder="请选择短信签名"
                filterable
              >
                <el-option
                  v-for="item in signList"
                  :key="item.signatureId"
                  :label="item.signatureContent"
                  :value="item.signatureId"
                />
              </el-select>
            </el-form-item>
            <!-- 第五行 -->

            <div class="marB10 marT20">
              <span class="asterisk fontS14">*</span>
              <span class="fontS14 marR20">短信内容</span>
              <el-button size="mini" @click="getSmsTemplate"
                >选择短信模板</el-button
              >
            </div>
            <!-- 第六行 -->
            <div class="marB10">
              <el-form-item prop="sendContent">
                <el-input
                  type="textarea"
                  :rows="10"
                  placeholder="请输入内容"
                  v-model="sendForm.sendContent"
                  :maxlength="sendContentLength"
                  show-word-limit
                  @input="inputSendContent"
                />
              </el-form-item>
              <div class="marT20 ft3">
                当前字数大约
                <span class="asterisk fontS14">{{ currentWordCount }}</span>
                字，按
                <span class="asterisk fontS14">{{ numberSMS }}</span>
                条短信计费，当前已选择
                <span class="asterisk fontS14">{{
                  sendForm.detailItem.length || 0
                }}</span>
                个号码(短信签名、拒收请回复R
                也记录在内,仅供参考,实际以历史记录为准)
                每天只能发一条相同模板的营销短信
              </div>
            </div>

            <!-- 第七行 -->
            <div>营销短信建议：早上8点 到晚上8点发送，否则到第二天才能收到</div>
            <!-- 第八行 -->

            <div class="x-f seven">
              <el-form-item prop="sendType">
                <span class="asterisk fontS14">*</span>
                <span class="fontS14 marR20">发送时间</span>
                <el-radio-group v-model="sendForm.sendType">
                  <el-radio :label="1">立即发送</el-radio>
                  <el-radio :label="2">定时发送</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="sendForm.sendType == 2">
                <el-form-item prop="planSendTime">
                  <span class="fontS14 marR10 marL20">计划发送时间</span>
                  <el-date-picker
                    v-model="sendForm.planSendTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    size="mini"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <!-- 短信模板弹窗 -->
      <el-dialog
        :visible.sync="dialogVisible"
        title="短信模板列表"
        v-dialogDrag="true"
        width="70%"
      >
        <templateCom :isOperation="false" @templateItemCom="templateItemCom" />
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="temConfirm">确 定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </el-form>
    <Dialog
      ref="tablePage"
      :options.sync="dialogOptions"
      @handleEvent="handleEvent"
    />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import { getBookUsableFillcount } from "@/api/marketing/sms/buy"; //短信购买
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import templateCom from "../components/templateCom.vue"; //短信模板
import { signListAPI } from "@/api/marketing/sms/sign"; //短信签名
import { sendListAPI, sendAddAPI } from "@/api/marketing/sms/send"; //短信营销
export default {
  name: "send",
  components: {
    cardTitleCom,
    topOperatingButton,
    templateCom,
    Dialog
  },
  data() {
    return {
      dialogType: 'vip',
      dialogOptions: {
          curType: '', //当前类型(门店/会员/商品/类别/品牌)
          title: '选择',
          width: 1250,
          show: false,
          type: 'TreeAndTable'
      },
      //模板信息
      templateItem: undefined,
      //短信签名
      signList: [],
      //短信模板弹窗
      dialogVisible: false,
      //遮罩
      loading: false,
      //选择对象遮罩
      loadingTable: false,
      bookTotal: 0,
      //表单
      sendForm: {
        detailItem: [],
        planSendTime: 1,
        smsType: 1,
        sendContent: "",
      },
      // 校验
      rules: {
        detailItem: [
          {
            required: true,
            message: "发送对象不能为空",
            trigger: ["blur", "change"],
          },
        ],
        smsType: [
          {
            required: true,
            message: "短信类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        sendSignature: [
          {
            required: true,
            message: "短信签名不能为空",
            trigger: ["blur", "change"],
          },
        ],
        sendContent: [
          {
            required: true,
            message: "短信内容不能为空",
            trigger: ["blur", "change"],
          },
        ],
        sendType: [
          {
            required: true,
            message: "发送时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        planSendTime: [
          {
            required: true,
            message: "计划发送时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    isSmsType2() {
      return this.sendForm.smsType === 2;
    },
    sendContentLength() {
      let signatureContentLength = this.signatureContentLength;
      let length = this.isSmsType2
        ? 294 - signatureContentLength
        : 300 - signatureContentLength;
      return length;
    },
    numberSMS() {
      /*
      通知类短信:70个字符为一条收费，超过70个字字符为长短信，就是以67个字一条收费，如:超过70个字符扣两条，超过134个字扣三条，以此类推，最多可以输入300个字符，300个字扣5条的费用。
      广告类短信:64个字符一条，包括签名，(65.66.67.68.69.70个字不可提交。)64个字符以下扣条，70个字符以上扣两条，超过128个字扣三条，以此类推。
      通知短信:签名+内容
      广告短信:签名+内容+拒收请回复R
      所以广告短信计费方式要比通知短信少6字，因为系统自带了 拒收请回复R
      */
      if(this.currentWordCount<=70){
        return 1;
      }else{
        return Math.ceil(
          this.currentWordCount / (this.isSmsType2 ? 64 : 67)
        );
      }
    },
    signatureContentLength() {
      return (
        this?.signList?.find(
          (item) => item.signatureId == this.sendForm.sendSignature
        )?.signatureContent.length || 0
      );
    },
    currentWordCount() {
      return (
        this.signatureContentLength +
        this.sendForm?.sendContent?.length +
        (this.isSmsType2 ? 6 : 0)
      );
    },
  },
  created() {
    //初始化全部的数据
    this.getInitializeData();
  },
  watch: {
    "sendForm.sendType"() {
      if (this.sendForm.sendType == 1) {
        this.sendForm.planSendTime = undefined;
      }
    },
  },
  methods: {
    inputSendContent() {
      if (!this.sendForm.sendSignature) {
        this.sendForm.sendContent = "";
        return this.$alert("请先选择短信签名", "", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
      }
    },
    //保存设置
    async submitForm() {
      if (!(this.sendForm.detailItem && this.sendForm.detailItem.length > 0)) {
        this.$message.error("发送对象不能为空");
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.$confirm(
            `${
              this.sendForm.sendType == 1 ? "立即发送" : "定时发送"
            }, 是否继续?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(async () => {
              this.sendForm.isSend = true;
              await sendAddAPI(this.sendForm);
              //加载页面
              await this.getList();
              this.$message({
                type: "success",
                message: `${
                  this.sendForm.sendType == 1 ? "立即发送" : "定时发送"
                }成功`,
              });
            })
            .catch(async () => {
              this.sendForm.isSend = false;
              await sendAddAPI(this.sendForm);
              //加载页面
              await this.getList();
              this.$message({
                type: "info",
                message: "已保存为草稿",
              });
            });
        }
      });
    },
    // 表单重置
    reset() {
      this.sendForm = {
        detailItem: [],
        planSendTime: 1,
        smsType: 1,
        sendContent: "",
      };
      // this.resetForm('form')
    },
    //模板确认键
    temConfirm() {
      this.sendForm.sendContent = this.templateItem.templateContent;
      this.dialogVisible = false;
    },
    //选中模板
    templateItemCom(value) {
      this.templateItem = value;
    },
    //初始化页面数据
    async getInitializeData() {
      try {
        this.loading = true;
        //短信签名
        const res = await signListAPI();
        res.rows.forEach(item=>{
          item.signatureContent= `[${item.signatureContent}]`
        })
        this.signList = res.rows;
        //加载页面
        await this.getList();
      } catch (error) {
        this.loading = false;
      }
    },
    //加载页面
    async getList() {
      if (!this.loading) {
        this.loading = true;
      }
      const res = await sendListAPI();
      if (res.rows && res.rows.length > 0) {
        this.sendForm = res.rows[0];
      }
      const res1 = await getBookUsableFillcount();
      this.bookTotal = res1.data || 0;
      this.loading = false;
    },
    //清空全部
    getEmptyOut() {
      this.sendForm.detailItem = [];
      this.$message({
        message: "清空成功",
        type: "success",
      });
    },
    //关闭
    shutDown() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$destroy();
      this.$router.go(-1);
    },
    //导入客户
    getSelectCustomerCom(value) {
      this.loadingTable = true;
      if (value.length == 0) {
        this.loadingTable = false;
        return;
      }
      //会员客户二选一
      if (
        this.sendForm.detailItem.length > 0 &&
        this.sendForm.detailItem[0].clientType == 1
      ) {
        this.sendForm.detailItem = [];
      }
      //去重
      value = value.filter((item) => {
        return this.sendForm.detailItem.every(
          (v) => v.clientId != item.partnerId
        );
      });
      if (value.length == 0) {
        this.loadingTable = false;
        return;
      }
      value.forEach((v) => {
        this.$set(v, "clientName", v.partnerName);
        this.$set(v, "clientId", v.partnerId);
        this.$set(v, "clientTel", v.tel);
        this.$set(v, "clientType", 2);
      });
      this.sendForm.detailItem.push(...value);
      this.sendForm.clientType = 2;
      this.loadingTable = false;
    },
    async handleEvent(type, row) {
      switch (type) {
        case 'dialogChange':
          if (this.dialogType == 'vip') {
            this.getVipS(this.dialogOptions.formData.table.check)
          } else if (this.dialogType == 'customer') {
            this.getSelectCustomerCom(this.dialogOptions.formData.table.check)
          }
          break
        default:
          break
      }
    },
    //选择会员、客户，弹窗
    getOpenDialog(value) {
      this.dialogType = value;
      this.dialogOptions = {
        title: value = 'vip' ? '选择会员' : "选择客户",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: this.dialogType }),
      }
    },
    //导入会员
    getVipS(value) {
      this.loadingTable = true;
      if (value.length == 0) {
        this.loadingTable = false;
        return;
      }
      //会员客户二选一
      if (
        this.sendForm.detailItem.length > 0 &&
        this.sendForm.detailItem[0].clientType == 2
      ) {
        this.sendForm.detailItem = [];
      }
      //去重
      value = value.filter((item) => {
        return this.sendForm.detailItem.every((v) => v.clientId != item.vipId);
      });
      if (value.length == 0) {
        this.loadingTable = false;
        return;
      }
      value.forEach((v) => {
        this.$set(v, "clientName", v.vipName);
        this.$set(v, "clientId", v.vipId);
        this.$set(v, "clientTel", v.tel);
        this.$set(v, "clientType", 1);
      });
      this.sendForm.detailItem.push(...value);
      this.sendForm.clientType = 1;

      this.loadingTable = false;
    },
    //选择短信模板弹窗
    getSmsTemplate() {
      this.dialogVisible = true;
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.sendForm.detailItem.splice(index + 1, 0, {});
      } else {
        if (this.sendForm.detailItem.length <= 1) return;
        this.sendForm.detailItem.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: #eaeaea;
  padding: 40px 10px 6px 10px;
  ::v-deep .el-card {
    font-size: 14px;
    color: #333;
  }
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  .cardMessage {
    min-height: 850px;
    .wholeMessage {
      padding: 6px 10px;
      /* 第七行 */
      .seven {
        height: 36px;
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0 20px;
  }
  ::v-deep .el-form-item {
    margin: 0;
    padding: 0;
  }
}
.ft3 {
  font-size: 14px !important;
  color: #333 !important;
}
</style>
